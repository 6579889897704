import { Suspense, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { 
  isPendingRequestForPMResource,
  isInitializedRequestForPMResource 
} from '../../common/src/selectors/requestStateSelectors'
import { PM_API_RESOURCES } from '../../common/src/constants'
import { Loading } from '../../components/basic/Loading'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { BasicItemList } from '../../components/item/basicList/BasicItemList'
import { useTranslatedPlaceholders } from '../../hooks/translationHooks'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { recentlyVisitedView } from '../../actions/viewInitializationActions'

export const HomeRecents = ({ onSelectItem, items }) => {
  const dispatch = useDispatch()
  const placeholders = useTranslatedPlaceholders('home.recent.placeholder_', 3)
  const loading = useSelector(state => isPendingRequestForPMResource(state, PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS))
  const selectedItemId = useSelectedItemId()
  
  // Use one-time initialization pattern with useRef
  const initialized = useRef(false)
  const dataInitialized = useSelector(state => 
    isInitializedRequestForPMResource(state, PM_API_RESOURCES.RECENTLY_VISITED_COMMENTS)
  )
  
  // Only fetch data ONCE when the component mounts and data hasn't been initialized yet
  useEffect(() => {
    if (!initialized.current && !dataInitialized) {
      dispatch(recentlyVisitedView())
      initialized.current = true
    }
  }, [dispatch, dataInitialized])
  
  const placeholder = useRandomValueFromArray(placeholders)
  if (!items?.length && !loading) {
    return (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder} />
      </Suspense>
    )
  }

  return (
    <BasicItemList
      items={items}
      selectedItemId={selectedItemId}
      onClickItem={onSelectItem}
      loading={loading}
      shouldShowProject
    />
  )
}
