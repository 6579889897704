import { memo, useCallback, useDeferredValue, useEffect, useState, useRef } from 'react'
import { useFilteredAndSortedProjectsArray } from '../../common/src/hooks/projectHooks'
import { projectHelper, stateHelper, itemHelper } from '../../common/src/helpers'
import { cn } from '@appfluence/classnames'
import { TaskListAdd, CalendarAgenda, Mention } from '@/components/BundledIcons'
import { useNotificationCounts } from '../../hooks/useNotificationCounts'
import { useAgendaItems } from '@/queries/agenda'
import { isSameDay, startOfDay } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'

// Custom hook to get inbox items count
const useInboxItemsCount = () => {
  const inboxItems = useSelector(stateHelper.getInboxPlusItems)
  return inboxItems ? inboxItems.size : 0
}
import { getProjectsPanelShowItemCounts, getHomeAgendaMode, getProjectViewPreference } from '../../selectors/uiSelectors'
import { HOME_DROPDOWN_OPTIONS, setProjectsPanelShowItemCounts } from '../../actions/uiActions'
import { getPathnameClean, getPreferredViewPathForProject } from '../../helpers/routeHelper'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ICON_NAMES } from '../../common/src/msIcons'
import { projectsView as initProjectsView } from '../../actions/viewInitializationActions'
import {
  Button,
  CounterBadge,
  Input,
  makeStyles,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemCheckbox,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Spinner,
  ToggleButton,
} from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { Icon } from '@fluentui/react'
import { PinFilled, StarFilled } from '@fluentui/react-icons'
import {
  Calculator,
  CalculatorArrowClockwise,
  Filter,
  FilterDismiss,
  Options,
  PanelLeftContract,
  PanelLeftExpand,
} from '../BundledIcons'
import {
  PROJECTS_SORT_TYPE,
  setDefaultFilterProjects,
  setProjectsTextFilter,
} from '../../common/src/actions/filtersActions'
import {
  getProjectFiltersCount,
  getProjectsSortType,
  getProjectsTextFilter,
} from '../../common/src/selectors/filtersSelectors'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { useOnProjectDragEnd } from '../../hooks/projectHooks'
import { useAllProjectCounters } from '../../queries/projectCounters'
import { ProjectOptionsMenu } from '../../views/project/ProjectOptionsButton'
import { usePositioningMouseTarget } from '@fluentui/react-positioning'
import { ProjectsFiltersPanel } from '../../views/filters/ProjectsFiltersPanel'
import { useSelectedProjectId } from '../../hooks/PMHooks'
import { isSearchProjectLeftPanelHotKey, useHotkey } from '@/hooks/useHotkey'

const useStyles = makeStyles({
  expandButton: {
    marginRight: '0.5rem',
  },
})

const SORT_MODES_TRANSLATION_KEYS = {
  [PROJECTS_SORT_TYPE.INDEX]: 'project_filters.manually',
  [PROJECTS_SORT_TYPE.NAME]: 'project_filters.alphabetically',
  [PROJECTS_SORT_TYPE.TIMESTAMP]: 'project_filters.modification_date',
}

const SHOW_ITEM_COUNTS = 'showItemCounts'

const ProjectsPanel_ = ({
  collapsed = false,
  collapsePanel,
  expandPanel,
  refetchCounters,
  isLoadingCounters,
  counters,
}) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const sortMode = useSelector(state => getProjectsSortType(state, FILTER_REDUCER_KEYS.PROJECTS_PANEL))
  const sortedFilteredProjects = useFilteredAndSortedProjectsArray(FILTER_REDUCER_KEYS.PROJECTS_PANEL)
  const pinnedProjects = sortedFilteredProjects.filter(projectHelper.isPinned)
  const starredProjects = sortedFilteredProjects.filter(
    project => projectHelper.isStarred(project) && !projectHelper.isPinned(project)
  )
  const notStarredProjects = sortedFilteredProjects.filter(
    project => !projectHelper.isStarred(project) && !projectHelper.isPinned(project)
  )
  
  // Get delegation and mention notifications count
  const { delegation: delegationCount, mention: mentionCount } = useNotificationCounts()
  
  // Get agenda items and determine counts
  const agendaMode = useSelector(getHomeAgendaMode) || HOME_DROPDOWN_OPTIONS.OWNED
  const { items: agendaItems } = useAgendaItems(agendaMode)
  const now = new Date()
  const startOfToday = startOfDay(now)
  const dueTodayCount = agendaItems.filter(item => isSameDay(now, itemHelper.getDueDate(item))).size
  const overdueLastMonthCount = agendaItems.filter(item => itemHelper.getDueDate(item) < startOfToday).size
  const dueThisWeekCount = agendaItems.size - overdueLastMonthCount
  
  // Format the agenda summary message with concise format
  const agendaSummaryLabel = dueTodayCount
    ? t('left_panel.agenda_today', 'Today')
    : dueThisWeekCount
      ? t('left_panel.agenda_this_week', 'This Week')
      : t('left_panel.agenda_overdue', 'Overdue')
  
  const agendaCount = dueTodayCount || dueThisWeekCount || overdueLastMonthCount
  // Removed unused variable: agendaSummary
  const hasAgendaItems = agendaItems && agendaItems.size > 0
  
  // Get inbox items count
  const inboxCount = useInboxItemsCount()

  const filtersCount = useSelector(state => getProjectFiltersCount(state, FILTER_REDUCER_KEYS.PROJECTS_PANEL))

  // Internal state for optimistic reordering
  const [currentProjects, setCurrentProjects] = useState(sortedFilteredProjects)
  useEffect(() => {
    setCurrentProjects(sortedFilteredProjects)
  }, [sortedFilteredProjects])

  const selectedProjectId = useSelectedProjectId()
  const showItemCounts = useSelector(getProjectsPanelShowItemCounts)

  const searchQuery = useSelector(state => getProjectsTextFilter(state, FILTER_REDUCER_KEYS.PROJECTS_PANEL))
  const deferredSearchQuery = useDeferredValue(searchQuery)
  const setSearchQuery = useCallback(
    newSearchQuery => dispatch(setProjectsTextFilter(newSearchQuery, FILTER_REDUCER_KEYS.PROJECTS_PANEL)),
    [dispatch]
  )

  const [filtersPanelOpen, setFiltersPanelOpen] = useState(false)

  // Use an effect to check for URL parameters and apply filters
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('project_tags') || params.has('project_sort')) {
      // Apply the filters with a short delay to ensure everything is initialized
      setTimeout(() => {
        import('../../hooks/filterHooks').then(module => {
          const { applyProjectFiltersFromUrl } = module;
          applyProjectFiltersFromUrl(dispatch);
          window.dispatchEvent(new Event('filterchange'));
        });
      }, 500);
    }
  }, [dispatch]);

  const hideFiltersPanel = useCallback(() => setFiltersPanelOpen(false), [])

  useEffect(() => {
    dispatch(initProjectsView())
  }, [dispatch])

  // Always include special sections regardless of search/filter state
  const topSections = [
    // New Assignments section at the top (if has count)
    ...(delegationCount > 0 ? [{
      key: 'assignments',
      title: (
        <span className="flex items-center">
          <TaskListAdd className="text-pm-theme-primary text-xl" />
          {t('left_panel.assignments_button_name', 'New Assignments')}
        </span>
      ),
      special: true,
      hideTitle: true,
      content: (
        <Link 
          to={{pathname: '/app/home/alerts/delegation'}}
          className={cn(
            'flex cursor-pointer items-center gap-2 rounded px-3 py-1 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800',
          )}
        >
          <TaskListAdd className="text-pm-theme-primary text-xl" />
          <span className="min-w-0 break-words text-black dark:text-white">
            {t('left_panel.assignments_button_name', 'New Assignments')}
            <span className="badge-margin-left notification-badge">
              {delegationCount}
            </span>
          </span>
        </Link>
      ),
      collapsedContent: (
        <Tooltip
          content={t('left_panel.assignments_button_name', 'New Assignments')}
          positioning="after"
          withArrow
          relationship="label"
        >
          <Link 
            to={{pathname: '/app/home/alerts/delegation'}}
            className={cn(
              'flex cursor-pointer items-center justify-center py-2 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800 relative',
            )}
          >
            <TaskListAdd className="text-pm-theme-primary text-xl" />
            <span className="notification-badge notification-badge-absolute">
              {delegationCount}
            </span>
          </Link>
        </Tooltip>
      )
    }] : []),
    // Mentions section (if has count)
    ...(mentionCount > 0 ? [{
      key: 'mentions',
      title: (
        <span className="flex items-center">
          <Mention className="text-pm-theme-primary text-xl" />
          {t('left_panel.mentions_button_name', 'Mentions')}
        </span>
      ),
      special: true,
      hideTitle: true,
      content: (
        <Link 
          to={{pathname: '/app/home/alerts/mention'}}
          className={cn(
            'flex cursor-pointer items-center gap-2 rounded px-3 py-1 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800',
          )}
        >
          <Mention className="text-pm-theme-primary text-xl" />
          <span className="min-w-0 break-words text-black dark:text-white">
            {t('left_panel.mentions_button_name', 'Mentions')}
            <span className="badge-margin-left notification-badge">
              {mentionCount}
            </span>
          </span>
        </Link>
      ),
      collapsedContent: (
        <Tooltip
          content={t('left_panel.mentions_button_name', 'Mentions')}
          positioning="after"
          withArrow
          relationship="label"
        >
          <Link 
            to={{pathname: '/app/home/alerts/mention'}}
            className={cn(
              'flex cursor-pointer items-center justify-center py-2 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800 relative',
            )}
          >
            <Mention className="text-pm-theme-primary text-xl" />
            <span className="notification-badge notification-badge-absolute">
              {mentionCount}
            </span>
          </Link>
        </Tooltip>
      )
    }] : []),
    // Agenda section (if has items)
    ...(hasAgendaItems ? [{
      key: 'agenda',
      title: (
        <span className="flex items-center">
          <CalendarAgenda className="text-pm-theme-primary text-xl" />
          {t('home.agenda')}
        </span>
      ),
      special: true,
      hideTitle: true,
      content: (
        <Link 
          to={getPathnameClean("/app/home/agenda/")}
          className={cn(
            'flex cursor-pointer items-center gap-2 rounded px-3 py-1 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800',
          )}
        >
          <CalendarAgenda className="text-pm-theme-primary text-xl" />
          <span className="min-w-0 break-words text-black dark:text-white">
            {agendaSummaryLabel} <span className="badge-margin-left-sm notification-badge">{agendaCount}</span>
          </span>
        </Link>
      ),
      collapsedContent: (
        <Tooltip
          content={`${agendaSummaryLabel} (${agendaCount})`}
          positioning="after"
          withArrow
          relationship="label"
        >
          <Link 
            to={getPathnameClean("/app/home/agenda/")}
            className={cn(
              'flex cursor-pointer items-center justify-center py-2 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800 relative',
            )}
          >
            <CalendarAgenda className="text-pm-theme-primary text-xl" />
            {agendaCount > 0 && (
              <span className="notification-badge notification-badge-absolute">
                {agendaCount}
              </span>
            )}
          </Link>
        </Tooltip>
      )
    }] : []),
    // Inbox section 
    {
      key: 'inbox',
      title: (
        <span className="flex items-center">
          <Icon iconName={ICON_NAMES.Inbox} className="text-md ml-0.5 mr-2 text-pm-theme-primary" />
          {t('left_panel.inbox_button_name')}
        </span>
      ),
      special: true,
      hideTitle: true,
      content: (
        <Link 
          to={getPathnameClean("/app/inbox/")}
          className={cn(
            'flex cursor-pointer items-center gap-2 rounded px-3 py-1 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800',
          )}
        >
          <Icon iconName={ICON_NAMES.Inbox} className="text-pm-theme-primary text-xl" />
          <span className="min-w-0 break-words text-black dark:text-white">
            {t('left_panel.inbox_button_name', 'Inbox')}
            {inboxCount > 0 && (
              <span className="badge-margin-left notification-badge">
                {inboxCount}
              </span>
            )}
          </span>
        </Link>
      ),
      collapsedContent: (
        <Tooltip
          content={t('left_panel.inbox_button_name', 'Inbox')}
          positioning="after"
          withArrow
          relationship="label"
        >
          <Link 
            to={getPathnameClean("/app/inbox/")}
            className={cn(
              'flex cursor-pointer items-center justify-center py-2 no-underline hover:bg-neutral-100 dark:hover:bg-neutral-800 relative',
            )}
          >
            <Icon iconName={ICON_NAMES.Inbox} className="text-pm-theme-primary text-xl" />
            {inboxCount > 0 && (
              <span className="notification-badge notification-badge-absolute">
                {inboxCount}
              </span>
            )}
          </Link>
        </Tooltip>
      )
    }
  ];
  
  const sections = deferredSearchQuery
    ? [...topSections, { key: 'search', title: t('projects_panel.search_results'), projects: sortedFilteredProjects }]
    : [
        ...topSections,
        ...(pinnedProjects.length && sortMode !== PROJECTS_SORT_TYPE.INDEX
          ? [
              {
                key: 'pinned',
                title: (
                  <span className="flex items-center">
                    <PinFilled className="text-md ml-0.5 mr-2" />
                    {t('projects_panel.pinned')}
                  </span>
                ),
                projects: pinnedProjects,
              },
            ]
          : []),
        ...(starredProjects.length && sortMode !== PROJECTS_SORT_TYPE.INDEX
          ? [
              {
                key: 'starred',
                title: (
                  <span className="flex items-center">
                    <StarFilled className="text-md ml-0.5 mr-2 text-yellow-400" />
                    {t('projects_panel.starred')}
                  </span>
                ),
                projects: starredProjects,
              },
            ]
          : []),
        {
          key: 'sorted',
          title: t(SORT_MODES_TRANSLATION_KEYS[sortMode]),
          projects: sortMode === PROJECTS_SORT_TYPE.INDEX ? currentProjects : notStarredProjects,
        },
      ]

  const onDragEnd = useOnProjectDragEnd({ currentProjects, setCurrentProjects })

  const [contextualMenuOpen, setContextualMenuOpen] = useState(false)
  const [contextualMenuTarget, setContextualMenuTarget] = usePositioningMouseTarget()
  const [contextualMenuProject, setContextualMenuProject] = useState(null)

  const onContextMenuOpenChange = useCallback((ev, { open }) => {
    setContextualMenuOpen(open)
  }, [])

  const searchRef = useRef(null)
  const containerRef = useRef(null)
  const focusSearchInput = useCallback(() => {
    searchRef.current?.focus()
  }, [searchRef])
  useHotkey(isSearchProjectLeftPanelHotKey, focusSearchInput, true)

  const handleSearchChange = useCallback((ev, data) => {
    setSearchQuery(data.value)
  }, [setSearchQuery])
  
  const handleShowFiltersPanel = useCallback(() => {
    // Apply project filters from URL before opening the panel
    import('../../hooks/filterHooks').then(module => {
      const { applyProjectFiltersFromUrl } = module;
      applyProjectFiltersFromUrl(dispatch);
      setFiltersPanelOpen(true);
      window.dispatchEvent(new Event('filterchange'));
    });
  }, [dispatch, setFiltersPanelOpen])
  
  const handleClearFilters = useCallback(() => {
    // Clear filters in Redux
    dispatch(setDefaultFilterProjects(FILTER_REDUCER_KEYS.PROJECTS_PANEL));
    
    // Import the helper function to clear URL parameters
    import('../../hooks/filterHooks').then(module => {
      const { clearProjectFilterUrlParams } = module;
      clearProjectFilterUrlParams();
    });
    
    // Trigger UI update
    window.dispatchEvent(new Event('filterchange'));
  }, [dispatch])

  const handleToggleItemCounts = useCallback(() => {
    dispatch(setProjectsPanelShowItemCounts(!showItemCounts))
  }, [dispatch, showItemCounts])

  const handleCollapseExpandClick = useCallback(() => {
    if (collapsed) expandPanel()
    else collapsePanel()
  }, [collapsed, expandPanel, collapsePanel])

  const handleContextMenu = useCallback((project) => (ev) => {
    ev.preventDefault()
    setContextualMenuOpen(true)
    setContextualMenuTarget(ev)
    setContextualMenuProject(project)
  }, [setContextualMenuOpen, setContextualMenuTarget, setContextualMenuProject])

  const onKeyDown = useCallback(evt => {
    if (evt.key === 'Enter') {
      containerRef.current?.querySelector('a')?.click?.()
    }
  }, [containerRef])

  return (
    <aside className="mt-1 flex h-full flex-col">
      <header className="relative flex w-full justify-center">
        {showItemCounts && isLoadingCounters && <Spinner size="extra-small" className="absolute -bottom-9 right-3" />}
        {!collapsed && (
          <Input
            appearance="filled-darker"
            className="mx-1 w-0 flex-1"
            input={{ className: 'placeholder-shown:text-ellipsis' }}
            placeholder={t('projects_panel.search_placeholder')}
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={onKeyDown}
            ref={searchRef}
          />
        )}
        {!collapsed && (
          <Menu
            checkedValues={{ [SHOW_ITEM_COUNTS]: showItemCounts ? [SHOW_ITEM_COUNTS] : [] }}
            positioning={{ autoSize: true }}
          >
            <Tooltip content="Projects Panel Options" relationship="label" positioning="below">
              <MenuTrigger disableButtonEnhancement>
                <ToggleButton
                  appearance="subtle"
                  icon={
                    <span className="relative flex h-full w-full items-center justify-center">
                      <Options />
                      {Boolean(filtersCount) && (
                        <CounterBadge size="small" className="!absolute -right-1 -top-1 !h-5 !w-5 !rounded-full dark:!bg-blue-600 dark:!text-white">
                          {filtersCount}
                        </CounterBadge>
                      )}
                    </span>
                  }
                  checked={Boolean(filtersCount)}
                />
              </MenuTrigger>
            </Tooltip>
            <MenuPopover>
              <MenuList>
                <MenuItem 
                  icon={<Filter />} 
                  onClick={handleShowFiltersPanel}
                >
                  {t('projects_panel.show_filters_panel')}
                  {Boolean(filtersCount) && ` (${filtersCount})`}
                </MenuItem>
                {Boolean(filtersCount) && (
                  <MenuItem
                    icon={<FilterDismiss />}
                    onClick={handleClearFilters}
                  >
                    {t('projects_panel.clear_filters')}
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItemCheckbox
                  name={SHOW_ITEM_COUNTS}
                  value={SHOW_ITEM_COUNTS}
                  onClick={handleToggleItemCounts}
                  icon={<Calculator />}
                >
                  {t('projects_panel.show_item_counts')}
                </MenuItemCheckbox>
                {showItemCounts && (
                  <>
                    <MenuDivider />
                    <MenuItem onClick={refetchCounters} icon={<CalculatorArrowClockwise />}>
                      {t('projects_panel.refresh_counts_tooltip')}
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </MenuPopover>
          </Menu>
        )}
        <Tooltip
          content={collapsed ? t('projects_panel.expand_panel') : t('projects_panel.collapse_panel')}
          relationship="label"
          positioning="below"
        >
          <Button
            className={styles.expandButton}
            appearance="subtle"
            size={collapsed ? 'large' : 'medium'}
            icon={collapsed ? <PanelLeftExpand /> : <PanelLeftContract />}
            onClick={handleCollapseExpandClick}
          />
        </Tooltip>
      </header>
      <nav ref={containerRef} className="flex flex-1 flex-col gap-3 overflow-y-auto py-2">
        {sections.map((section) => (
          <DragDropContext key={section.key} onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="flex flex-col gap-1 px-1">
                  {!collapsed && !section.hideTitle && <span className="ml-3 text-base text-pm-theme-primary">{section.title}</span>}
                  {/* Special content for Inbox or other special sections */}
                  {section.special ? (
                    collapsed ? section.collapsedContent : section.content
                  ) : (
                    section.projects.map((project, index) => (
                      <ProjectListCell
                        key={projectHelper.getIdd(project)}
                        collapsed={collapsed}
                        project={project}
                        index={index}
                        selectedProjectId={selectedProjectId}
                        draggable={sortMode === PROJECTS_SORT_TYPE.INDEX}
                        showItemCounts={showItemCounts}
                        counters={counters?.[projectHelper.getIdd(project)]}
                        onContextMenu={handleContextMenu(project)}
                      />
                    ))
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ))}
        <ProjectOptionsMenu
          project={contextualMenuProject}
          open={contextualMenuOpen}
          onOpenChange={onContextMenuOpenChange}
          positioning={{ target: contextualMenuTarget }}
        />
      </nav>
      <ProjectsFiltersPanel
        isOpen={filtersPanelOpen}
        onDismiss={hideFiltersPanel}
        mode={FILTER_REDUCER_KEYS.PROJECTS_PANEL}
      />
    </aside>
  )
}

const MemoizedProjectsPanel_ = memo(ProjectsPanel_)

export const ProjectsPanel = props => {
  const showItemCounts = useSelector(getProjectsPanelShowItemCounts)
  const { refetch, isRefetching, isPending, data } = useAllProjectCounters(showItemCounts, false)
  const deferredCounters = useDeferredValue(data)
  return (
    <MemoizedProjectsPanel_
      {...props}
      refetchCounters={refetch}
      isLoadingCounters={isRefetching || isPending}
      counters={deferredCounters}
    />
  )
}

const ProjectListCell = ({
  index,
  project,
  collapsed,
  selectedProjectId,
  onClick,
  onContextMenu,
  draggable = false,
  showItemCounts = false,
  counters,
}) => {
  const id = projectHelper.getIdd(project)
  const name = projectHelper.getName(project)
  const selected = id === selectedProjectId
  
  // Use the proper selector to get the project view preference
  const projectViewPreference = useSelector(state => getProjectViewPreference(state, id))
  
  // Get the preferred path for this project
  const projectPath = getPreferredViewPathForProject(id, projectViewPreference)

  return (
    <Draggable draggableId={id.toString()} index={index} isDragDisabled={!draggable}>
      {(provided) => (
        <Tooltip
          content={name}
          positioning="after"
          withArrow
          relationship={collapsed ? 'label' : 'inaccessible'}
          visible={collapsed ? undefined : false}
        >
          <Link
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            to={getPathnameClean(projectPath)}
            key={projectHelper.getIdd(project)}
            className={cn(
              'flex cursor-pointer items-center gap-2 rounded px-3 py-1 no-underline',
              selected && 'bg-pm-appfluence'
            )}
            onClick={onClick}
            onContextMenu={onContextMenu}
            aria-current={selected ? 'page' : false}
          >
            <QuadrantSelector
              className={collapsed ? 'my-1' : 'self-start'}
              size={showItemCounts ? 40 : 20}
              readOnly
              project={project}
              showTooltips={!collapsed}
              counters={showItemCounts ? counters : undefined}
            />
            {!collapsed && <span className="min-w-0 break-words text-black dark:text-white">{name}</span>}
          </Link>
        </Tooltip>
      )}
    </Draggable>
  )
}
