import { DEFAULT_TEAMS_LOCATION } from '@/common/src/reducers/teamsKeys'
import type { CalendarColorName } from '@/views/calendar/PMCalendar.Common'

export const ACTIONS = {
  SET_IS_FIRST_LAUNCH: 'UI/IS_FIRST_LAUNCH',
  TOGGLE_ITEM_IN_MEETING_NOTES: 'UI/TOGGLE_ITEM_IN_MEETING_NOTES',
  UPDATE_COMMENT_NOT_SEND: 'UI/UPDATE_COMMENT_NOT_SEND',
  INCREASE_VIEWPORT_SCALE: 'UI/INCREASE_VIEWPORT_SCALE',
  DECREASE_VIEWPORT_SCALE: 'UI/DECREASE_VIEWPORT_SCALE',
  SET_ONE_ON_ONE_LAST_COLLABORATOR: 'UI/SET_ONE_ON_ONE_LAST_COLLABORATOR',
  SET_MATRIX_VIEW_MODE: 'UI/SET_MATRIX_VIEW_MODE',
  SET_PROJECT_VIEW_PREFERENCE: 'UI/SET_PROJECT_VIEW_PREFERENCE',
  INVITE_GROWTH_USER: 'UI/INVITE_GROWTH_USER',
  SELECT_MEETING: 'UI/SELECT_MEETING',
  SHOW_COMPLETED_ITEM_HELP: 'UI/SHOW_COMPLETED_ITEM_HELP',
  SELECT_NOTIFICATION: 'UI/SELECT_NOTIFICATION',
  SET_SHOW_TRIAL_MESSAGE_BAR: 'UI/SET_SHOW_TRIAL_MESSAGE_BAR',
  SET_HOME_AGENDA_MODE: 'UI/SET_HOME_AGENDA_MODE',
  SET_HOME_MY_ITEMS_MODE: 'UI/SET_HOME_MY_ITEMS_MODE',
  SET_ITEM_SELECTED: 'UI/SET_ITEM_SELECTED',
  CLEAR_MULTIPLE_SELECTION: 'UI/CLEAR_MULTIPLE_SELECTION',
  SET_MULTIPLE_SELECTION_ON: 'UI/SET_MULTIPLE_SELECTION_ON',
  SET_ITEMS_SELECTED: 'UI/SET_ITEMS_SELECTED',
  SET_PREFERS_DARK_MODE: 'UI/SET_PREFERS_DARK_MODE',
  ADD_ITEM_ID_TO_RECENTLY_VISITED: 'UI/ADD_ITEM_ID_TO_RECENTLY_VISITED',
  SET_LAST_DUE_TIMESTAMP_CREATED: 'UI/SET_LAST_DUE_TIMESTAMP_CREATED',
  SET_LAST_START_TIMESTAMP_CREATED: 'UI/SET_LAST_START_TIMESTAMP_CREATED',
  DISMISS_AI_MESSAGE: 'UI/DISMISS_AI_MESSAGE',
  SET_PM_PLATFORM: 'UI/SET_PM_PLATFORM',
  SET_PROJECTS_PANEL_SHOW_ITEM_COUNTS: 'UI/SET_PROJECTS_PANEL_SHOW_ITEM_COUNTS',
  SET_PROJECTS_DISPLAY_MODE: 'UI/SET_PROJECTS_DISPLAY_MODE',
  SET_WEBINAR_REMINDER_ENABLED: 'UI/SET_WEBINAR_REMINDER_ENABLED',
  SET_LAST_WEBINAR_ID_REMINDED: 'UI/SET_LAST_WEBINAR_ID_REMINDED',
  DISMISS_TRIAL_MESSAGE_BAR: 'UI/DISMISS_TRIAL_MESSAGE_BAR',
  SET_ITEMS_DISPLAY_MODE: 'UI/SET_ITEMS_DISPLAY_MODE',
  SET_CALENDAR_INFO_FOR_PROJECT: 'UI/SET_CALENDAR_INFO_FOR_PROJECT',
  SET_CALENDAR_INFO_DISPLAY_ALL: 'UI/SET_CALENDAR_INFO_DISPLAY_ALL',
  SET_ITEMS_SHOW_AVATARS: 'UI/SET_ITEMS_SHOW_AVATARS',
  SET_AGENDA_INCLUDE_CALENDAR_EVENTS: 'UI/SET_AGENDA_INCLUDE_CALENDAR_EVENTS',
} as const

export const RIGHT_PANEL = {
  PROJECT: 'PROJECT',
  ITEM: 'ITEM',
  GRAPH_RESOURCE: 'GRAPH_RESOURCE',
} as const

export const HOME_DROPDOWN_OPTIONS = {
  OWNED: 'OWNED',
  FOLLOWED: 'FOLLOWED',
  EVERYTHING: 'EVERYTHING',
} as const

export const setIsFirstLaunch = () => {
  return {
    type: ACTIONS.SET_IS_FIRST_LAUNCH,
  }
}

export const dismissTrialMessageBar = () => ({
  type: ACTIONS.DISMISS_TRIAL_MESSAGE_BAR,
})

export const dismissAIMessage = () => ({ type: ACTIONS.DISMISS_AI_MESSAGE })

export const clearItemMultipleSelection = () => ({
  type: ACTIONS.CLEAR_MULTIPLE_SELECTION,
})

export const showCompletedItemHelp = () => ({ type: ACTIONS.SHOW_COMPLETED_ITEM_HELP })

export const increaseViewportScale = () => ({ type: ACTIONS.INCREASE_VIEWPORT_SCALE })
export const decreaseViewportScale = () => ({ type: ACTIONS.DECREASE_VIEWPORT_SCALE })

export const selectMeeting = (meetingId: any) => {
  const payload = meetingId
  return {
    type: ACTIONS.SELECT_MEETING,
    payload,
  }
}

export const selectNotification = (notificationId: number) => {
  const payload = notificationId
  return {
    type: ACTIONS.SELECT_NOTIFICATION,
    payload,
  }
}

export const updateCommentNotSend = (comment: string, itemID: number) => {
  return (
    itemID && {
      type: ACTIONS.UPDATE_COMMENT_NOT_SEND,
      payload: { text: comment, itemID },
    }
  )
}

export const setOneOnOneLastCollaborator = (email: string, location: string = DEFAULT_TEAMS_LOCATION) => ({
  type: ACTIONS.SET_ONE_ON_ONE_LAST_COLLABORATOR,
  payload: { email, location },
})

export const toggleItemInMeetingNotes = () => ({ type: ACTIONS.TOGGLE_ITEM_IN_MEETING_NOTES })
export const setMatrixViewMode = (mode: string) => ({ type: ACTIONS.SET_MATRIX_VIEW_MODE, payload: { mode } })

export const setProjectViewPreference = (projectId: number, viewMode: string) => ({
  type: ACTIONS.SET_PROJECT_VIEW_PREFERENCE,
  payload: { projectId, viewMode }
})

export const markGrowthUserAsInvited = (email: string) => ({ type: ACTIONS.INVITE_GROWTH_USER, payload: { email } })
export const setShowTrialMessageBar = (display: boolean) => ({
  type: ACTIONS.SET_SHOW_TRIAL_MESSAGE_BAR,
  payload: display,
})

export const setHomeAgendaMode = (mode: string) => ({ type: ACTIONS.SET_HOME_AGENDA_MODE, payload: mode })
export const setHomeMyItemsMode = (mode: string) => ({ type: ACTIONS.SET_HOME_MY_ITEMS_MODE, payload: mode })

export const setItemSelectedInMultipleSelection = (itemId: number, selected: boolean) => ({
  type: ACTIONS.SET_ITEM_SELECTED,
  payload: { itemId, selected },
})
export const setMultipleSelectionOn = (on: boolean) => ({ type: ACTIONS.SET_MULTIPLE_SELECTION_ON, payload: on })
export const setItemsSelectedInMultiSelection = (itemIds: number[]) => ({
  type: ACTIONS.SET_ITEMS_SELECTED,
  payload: { itemIds },
})
export type DarkModePreference = 'light' | 'dark' | 'system'
export const setPrefersDarkMode = (prefersDarkMode: DarkModePreference) => ({
  type: ACTIONS.SET_PREFERS_DARK_MODE,
  payload: prefersDarkMode,
})
export const setLastDueTimestampCreated = (date: Date) => ({
  type: ACTIONS.SET_LAST_DUE_TIMESTAMP_CREATED,
  payload: date,
})
export const setLastStartTimestampCreated = (date: Date) => ({
  type: ACTIONS.SET_LAST_START_TIMESTAMP_CREATED,
  payload: date,
})
export const setPMPlatform = (platform: string) => ({ type: ACTIONS.SET_PM_PLATFORM, payload: platform })

export const addItemIdToRecentlyVisited = (itemId: number) => ({
  type: ACTIONS.ADD_ITEM_ID_TO_RECENTLY_VISITED,
  payload: itemId,
})

export const setProjectsPanelShowItemCounts = (show: boolean) => ({
  type: ACTIONS.SET_PROJECTS_PANEL_SHOW_ITEM_COUNTS,
  payload: show,
})

export const setProjectsDisplayMode = (mode: string) => ({
  type: ACTIONS.SET_PROJECTS_DISPLAY_MODE,
  payload: mode,
})

export const setWebinarReminderEnabled = (enabled: boolean) => ({
  type: ACTIONS.SET_WEBINAR_REMINDER_ENABLED,
  payload: enabled,
})

export const setLastWebinarIdReminded = (id: any) => ({
  type: ACTIONS.SET_LAST_WEBINAR_ID_REMINDED,
  payload: id,
})

export const setItemsDisplayMode = (mode: string) => ({
  type: ACTIONS.SET_ITEMS_DISPLAY_MODE,
  payload: mode,
})

interface ISetCalendarColorData {
  idd: number
  color?: CalendarColorName
  display?: boolean
}
export const setCalendarInfoForProject = (payload: ISetCalendarColorData) => ({
  type: ACTIONS.SET_CALENDAR_INFO_FOR_PROJECT,
  payload,
})

export const setCalendarInfoDisplayAll = (display: boolean) => ({
  type: ACTIONS.SET_CALENDAR_INFO_DISPLAY_ALL,
  payload: { display },
})

export const setItemsShowAvatars = (show: boolean) => ({
  type: ACTIONS.SET_ITEMS_SHOW_AVATARS,
  payload: show,
})

export const setAgendaIncludeCalendarEvents = (include: boolean) => ({
  type: ACTIONS.SET_AGENDA_INCLUDE_CALENDAR_EVENTS,
  payload: include,
})
